<header id="header" class="header fixed">
  <div class="navbar-top d-flex justify-content-between flex-lg-row flex-column">
    <div class="current-menu info-left flex-fill">

      <div class="nav-button d-xl-none ms-2">
        <button  (click)="toggleSidebar()" (keyup)="toggleSidebar()" class="text-body navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

          <i class="fa-solid fa-bars fa-lg" aria-hidden="true"></i>
        </button>
      </div>

      <div class="logo flex-fill d-sm-flex d-lg-block">
        <a [routerLink]="'/dashboard'" title="">
          <img src="assets/images/ScanNinjaLogo.png" alt="Scan Ninja" class="img-fluid">
        </a>
      </div><!-- /.logo -->

      <!-- TODO Add This Search to the list  -->
      <div class="box-search" *ngIf="false">
        <form action="#" method="get" accept-charset="utf-8">
          <button><img src="assets/images/icon/search.png" alt=""></button>
          <input type="text" placeholder="Search Projects..." name="Search">
        </form>
      </div>
      <!-- /.box-search -->

    </div><!-- /.current-menu -->

    <div class="account-info account-info my-4 d-none d-lg-block">

      <ng-container *ngIf="!mspAccount || !mspAccount.license_expiration_date">
        <i class="fa-solid fa-spinner fa-spin"></i>
      </ng-container>
      <ng-container *ngIf="mspAccount && mspAccount.license_expiration_date">
        <p>Account Expires: {{mspAccount.license_expiration_date * 1000 | date:'MMM d, y HH:mm'}}</p>
       <!--  <p>Account Assets: {{mspAccount.licensed_assets}}</p>
        <p>Domain: {{mspAccount.domains}}</p> -->
        <ng-container *ngIf="isExpiringSoon(mspAccount.license_expiration_date) && remainingTime && mspAccount.license_expiration_date">
          <p>Expires in:
            <span class="text-danger" *ngIf="remainingTime.hours > 0">{{ remainingTime.hours }} hours</span>
            <span class="text-danger" *ngIf="remainingTime.hours > 0 && remainingTime.minutes > 0"> and </span>
            <span class="text-danger" *ngIf="remainingTime.minutes > 0">{{ remainingTime.minutes }} minutes</span>
            <span class="text-danger" *ngIf="remainingTime.seconds > 0 && (remainingTime.hours === 0 && remainingTime.minutes === 0)"> and {{ remainingTime.seconds }} seconds</span>
          </p>
          <p class="text-end">
            <a [routerLink]="['/dashboard', 'my-account' ]" fragment="billing">Renew Now</a>
          </p>
        </ng-container>
      </ng-container>
    </div>
    <div class="my-account">
    <ul class="info-right">
      <li class="setting">
        <a class="waves-effect waves-teal" title="" [routerLink]="'/dashboard/scan-upload'" routerLinkActive="active" title="Create Scan">
          <i class="fa fa-file-import"></i>
        </a>
      </li>

      <li class="setting">
        <a class="waves-effect waves-teal" title="" [routerLink]="'/dashboard/scan-setup'" routerLinkActive="active" title="Create Scan">
          <i class="fa fa-plus"></i>
        </a>
      </li>

      <!-- <li class="setting">
        <a class="waves-effect waves-teal" title="" [routerLink]="'/dashboard/settings'" routerLinkActive="active">
          <img src="assets/images/icon/setting.png" alt="">
        </a>
      </li> -->
      <!-- /.setting -->
      <li class="notification" [class.open]="showNotyMenu">
        <a #notyButton class="waves-effect waves-teal" title="" (click)="showNotifications()" (keyup)="showNotifications()" tabindex="0">
          7
        </a>
        <div #notyMenu class="dropdown-menu notification-menu" [class.active]="showNotyMenu">
          <div class="box shadow-sm rounded mb-3">
            <div class="box-title border-bottom p-3 d-flex justify-content-between">
              <h6 class="m-0">Notifications</h6>
              <button type="button" class="btn close" aria-label="Close">
                <i class="text-danger bi bi-x"></i>
              </button>
            </div>
            <div class="box-body p-0">
              <ng-container *ngFor="let _ of [].constructor(20); let i=index">


              <div class="p-3 d-flex justify-content-between align-items-center border-bottom alert" [ngClass]="getClassByLevel(randomStrings[i].level)">
                <div class="dropdown-list-image mr-3">
                  <img class="rounded-circle" [src]="'http://placebeard.it/250/250?'+i+10" alt="">
                </div>
                <div class="font-weight-bold mr-3">
                  <div class="text-truncate">DAILY RUNDOWN: WEDNESDAY</div>
                  <div class="small">
                    <!-- Here is your Daily Run Down for the Day -->
                    <span [innerText]="randomStrings[i].message"></span>
                  </div>
                  <button type="button" class="mt-1 btn btn-outline-success btn-sm">View Details</button>
                </div>
                <div class="text-right text-muted pt-1 ">1d</div>
                <div class="">
                  <div class="btn-group">
                    <button type="button" class="btn close" aria-label="Close">
                      <i class="text-danger bi bi-x"></i>
                    </button>
                  </div>
                </div>
              </div>

            </ng-container>
            </div>
          </div>

          <!-- <ul>
            <li>
              <a class="waves-effect" [routerLink]="['/dashboard/my-account']" routerLinkActive="active" title="My Account">My Account</a>
            </li>
            <li>
              <a class="waves-effect" [routerLink]="'/dashboard/settings'" routerLinkActive="active" title="Settings">Setting</a>
            </li>
            <li>
              <a class="waves-effect" [routerLink]="'/logout'" routerLinkActive="active" title="Logout">Logout</a>
            </li>
          </ul> -->
        </div><!-- /.dropdown-menu -->
      </li><!-- /.notification -->
      <!-- User Profile Information  -->
      <li #profileBtn class="user" [class.open]="showProfileMenu" *ngIf="userModel" (click)="toggleMenu()" (keyup)="toggleMenu()" tabindex="0">
        <div class="avatar">
          <img *ngIf="userModel.photoURL" [src]="userModel.photoURL" alt="" class="img-fluid circle-mask">
          <img *ngIf="!userModel.photoURL" src="assets/images/icon/user-icon.svg" alt="" class="img-fluid circle-mask">
        </div>
        <div class="info">
          <p class="name" *ngIf="userModel.displayName" [innerText]="userModel.displayName"></p>
          <p class="name" *ngIf="!userModel.displayName && userModel.email" [innerText]="userModel.email"></p>
          <p class="options" *ngIf="userModel.role" [innerText]="userModel.role"></p>
        </div>
        <div class="arrow-down">
          <i class="fa fa-angle-down" aria-hidden="true"></i>
          <i class="fa fa-angle-up" aria-hidden="true"></i>
        </div>
        <div #profileMenu class="dropdown-menu" [class.active]="showProfileMenu">
          <div class="account-info account-info my-4 d-block d-lg-none">

            <ng-container *ngIf="!mspAccount || !mspAccount.license_expiration_date">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </ng-container>
            <ng-container *ngIf="mspAccount && mspAccount.license_expiration_date">
              <p>Account Expires: {{mspAccount.license_expiration_date * 1000 | date:'MMM d, y HH:mm'}}</p>
              <ng-container *ngIf="isExpiringSoon(mspAccount.license_expiration_date) && remainingTime && mspAccount.license_expiration_date">
                <p>Expires in:
                  <span class="text-danger" *ngIf="remainingTime.hours > 0">{{ remainingTime.hours }} hours</span>
                  <span class="text-danger" *ngIf="remainingTime.hours > 0 && remainingTime.minutes > 0"> and </span>
                  <span class="text-danger" *ngIf="remainingTime.minutes > 0">{{ remainingTime.minutes }} minutes</span>
                  <span class="text-danger" *ngIf="remainingTime.seconds > 0 && (remainingTime.hours === 0 && remainingTime.minutes === 0)"> and {{ remainingTime.seconds }} seconds</span>
                </p>
                <p class="text-end">
                  <a [routerLink]="['/dashboard', 'my-account' ]" fragment="billing">Renew Now</a>
                </p>
              </ng-container>
            </ng-container>
          </div>
          <ul class="list-unstyled">
            <li>
              <a class="waves-effect text-uppercase" [routerLink]="['/dashboard/my-account']" routerLinkActive="active" title="My Account">My Account</a>
            </li>
            <li>
              <a class="waves-effect text-uppercase" [routerLink]="['/dashboard/company']" routerLinkActive="active" title="My Company">My Company</a>
            </li>
            <li>
              <a class="waves-effect text-uppercase" [routerLink]="'/logout'" routerLinkActive="active" title="Logout">Logout</a>
            </li>
          </ul>
          <div class="d-flex justify-content-center align-items-center">
            <div class="form-check form-switch d-flex justify-content-center align-items-center">
              <input class="form-check-input toggle-switch d-none" type="checkbox" id="darkModeSwitch" (change)="toggleDarkMode()">
              <label class="form-check-label mt-2" for="darkModeSwitch">
                <i class="fas fa-sun sun-icon fa-lg" *ngIf="!dark"></i>
                <i class="fas fa-moon moon-icon fa-lg" *ngIf="dark"></i>
              </label>
            </div>
          </div>
        </div><!-- /.dropdown-menu -->
      </li><!-- /.user -->
      <!-- <li class="button-menu-right">
        <img src="assets/images/icon/menu-right.png" alt="">
      </li> -->
      <!-- /.button-menu-right -->
    </ul><!-- /.info-right -->
  </div>
  </div> <!-- /.navbar-top -->
</header><!-- /header <-->
